.sidebar__root {
  position: relative;
  z-index: 99;
}

.sidebar__paper {
  &.minimized {
    min-width: unset;
  }
  min-width: 260px;
  position: sticky;
  align-self: flex-start;
  top: 0;
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
}

.sidebar__minimize-button {
  position: fixed;
  height: 25px;
  width: 25px;
  z-index: 99999999;
  top: 1.2em;
  cursor: pointer;
  border-radius: 15%;
  background: #fff;
  border: 1px solid rgb(0 0 0 / 10%);
  outline: none;
  padding: 0;

  &:focus,
  &:active {
    outline: none;
  }
}

.sidebar__minimize-icon {
  &.minimized {
    transform: rotate(180deg);
  }

  stroke: #333;
  stroke-width: 5%;

  transition: transform 0.4s ease-in-out;
}

.sidebar__overflow-section {
  overflow: auto;

  &::-webkit-scrollbar:vertical {
    width: 0;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  -ms-overflow-style: none;
}

.sidebar__content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
}

.sidebar__env-badge {
  border-radius: 1.5em;
  line-height: 1.8;
}
