.modal-dialog {
  max-width: 100rem;
}

.endcard-upload-modal {
  .header {
    font-weight: bold;
  }
  .width-x1p5 {
    flex-grow: 1.5;
  }
  .width-x2 {
    flex-grow: 2;
  }
  .width-x4 {
    flex-grow: 4;
  }
}
