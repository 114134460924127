.thumbnail-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
  overflow: hidden; /* To ensure the thumbnail maintains its aspect ratio */
  border-radius: 8px; /* Optional: For rounded corners */
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 48px;
  color: white;
  opacity: 0.7;
  pointer-events: none;
}

.thumbnail-wrapper:hover .play-button {
  opacity: 1;
}

.preview-modal .modal-content {
  max-width: 800px;
  margin: auto;
}
