.creative-set-preview {
  pointer-events: none;
  opacity: 0.7;
  background-color: rgb(231, 229, 229);
  display: inline-block;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

.network-launches-table {
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  td {
    word-wrap: break-word;
  }
  .long-cell {
    width: 30%;
  }
  .short-cell {
    width: 5%;
  }
}
