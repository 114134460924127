.task-center-table {
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
  td {
    word-wrap: break-word;
  }
  .short-cell {
    width: 5%;
  }
  .long-cell {
    width: 20%;
  }
}
