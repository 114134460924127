.modal-dialog {
  max-width: 100rem;
}

.playable-upload-modal {
  .header {
    font-weight: bold;
  }
  .width-x2 {
    flex-grow: 2;
  }
  .width-x1p5 {
    flex-grow: 1.5;
  }
  .width-x4 {
    flex-grow: 4;
  }
}

.qr-code-checkbox input {
  width: 1.5rem;
  height: 1.5rem;
  top: 0.5rem;
}
