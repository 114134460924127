@use "sass:math";
$colors: #2172b8 #18a39b #82c545 #f8b739 #f06045 #ed2861 #c12680 #5d3191;

$inner: 30px;
$border: 6px;
$spacing: 1px;

div.spinner {
  animation: rotate 10s infinite linear;
  position: relative;
  display: block;

  margin: auto;

  $size: $inner + length($colors) * ($border + $spacing) * 2;

  width: $size;
  height: $size;

  i {
    animation: rotate 3s infinite cubic-bezier(0.09, 0.6, 0.8, 0.03);
    transform-origin: 50% 100% 0;

    position: absolute;
    display: inline-block;

    top: 50%;
    left: 50%;

    border: solid $border transparent;
    border-bottom: none;

    @each $current in $colors {
      $i: index($colors, $current);

      &:nth-child(#{$i}) {
        $size: $inner + $i * ($border + $spacing) * 2;

        animation-timing-function: cubic-bezier(
          0.09,
          0.3 * $i,
          0.12 * $i,
          0.03
        );

        width: $size;
        height: math.div($size, 2);

        margin-top: math.div(-$size, 2);
        margin-left: math.div(-$size, 2);

        border-color: $current;

        border-top-left-radius: $inner + $i * $border;
        border-top-right-radius: $inner + $i * $border;
      }
    }
  }
}

div.small-spinner {
  display: inline-block;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
